import React, { Component } from 'react';
import { SortableHeader } from './SortableHeader';
import { BinaryToggleButton } from './BinaryToggleButton';

export class Users extends Component {
    static displayName = Users.name;

    static textSortComp = { asc: (a, b) => a.localeCompare(b), desc: (a, b) => b.localeCompare(a) };
    static boolSortComp = { asc: (a, b) => a - b, desc: (a, b) => b - a };
    static columns = {
        avatarUri: { title: "Avatar", sortEnabled: false },
        name: { title: "Name", compare: Users.textSortComp },
        isAdmin: { title: "Admin", compare: Users.boolSortComp },
        isRecommender: { title: "Curator", compare: Users.boolSortComp }
    };

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            loading: true,
            currentSort: {}
        };

        this.setSort = this.setSort.bind(this);
        this.setAdmin = this.setAdmin.bind(this);
    }

    async componentDidMount() {
        await this.populateData();
        this.setSort({
            key: 'name',
            direction: 'asc'
        });
    }

    renderUsersTable(users) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr className="text-nowrap">
                        {Object.entries(Users.columns).filter(c => !c[1].adminOnly || this.props.userInfo.user.isAdmin).map(c =>
                            <th key={c[0]}><SortableHeader setSort={this.setSort} currentSort={this.state.currentSort} sortKey={c[0]} sortEnabled={c[1].sortEnabled !== false}>{c[1].title}</SortableHeader></th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {users.map(user =>
                        <tr key={user.id}>
                            <td><img alt="profile" src={user.avatarUri} style={{ clipPath: 'circle()', height: '24px', width: '24px', marginLeft: '5px' }} /></td>
                            <td>{user.name}</td>
                            <td><BinaryToggleButton value={user.isAdmin} onClick={value => this.setAdmin(user.id, value)} disabled={user.id === this.props.userInfo.user.id} /></td>
                            <td><BinaryToggleButton value={user.isRecommender} onClick={value => this.setRecommender(user.id, value)} /></td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        if (!this.props.userInfo.user.isAdmin) {
            return (
                <React.Fragment>
                    <h1>You must be an admin to manage users</h1>
                </React.Fragment>
            );
        }

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderUsersTable(this.state.users);

        return (
            <React.Fragment>
                <h1 id="tabelLabel">Users</h1>
                {contents}
            </React.Fragment>
        );
    }

    async setAdmin(id, isAdmin) {
        await fetch('api/users/' + id + '/isAdmin', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(isAdmin)
        });
        let state = this.state;
        let index = state.users.findIndex(u => u.id === id);
        state.users[index].isAdmin = isAdmin;
        this.setState(state);
    }

    async setRecommender(id, isRecommender) {
        await fetch('api/users/' + id + '/isRecommender', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(isRecommender)
        });
        let state = this.state;
        let index = state.users.findIndex(u => u.id === id);
        state.users[index].isRecommender = isRecommender;
        this.setState(state);
    }

    async populateData() {
        const response = await fetch('api/users');
        const data = await response.json();
        data.forEach(u => {
            u.name = u.discordUsername + '#' + u.discordDiscriminator;
        });
        this.setState({ users: data, loading: false, currentSort: {} });
    }

    doSort(users, sort) {
        users.sort((a, b) => {
            return Users.columns[sort.key].compare[sort.direction](a[sort.key], b[sort.key]);
        });
    }

    setSort(sort) {
        let state = this.state;
        state.currentSort = sort;
        this.doSort(state.users, sort);
        this.setState(state);
    }
}
