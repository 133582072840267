import React, { Component } from 'react';

export class VotePart extends Component {
    static displayName = VotePart.name;

    constructor(props) {
        super(props);

        this.processClick = this.processClick.bind(this);
    }

    render() {
        let filteredVotes = this.props.voteSource.filter(v => v.type === this.props.voteType);
        let currentUserVoted = this.props.userInfo.loggedIn && filteredVotes.some(v => v.user.id === this.props.userInfo.user.id);

        const boldStyle = {
            fontWeight: 'bold'
        };
        const normalStyle = {

        };
        const countStyle = currentUserVoted ? boldStyle : normalStyle;

        let style = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        };

        if (this.props.voteEnabled !== false) {
            style.cursor = 'pointer';
        }
        
        return (
            <div style={style} onClick={() => this.processClick(currentUserVoted)}>
                {this.props.symbol}
                <span style={countStyle}>{filteredVotes.length}</span>
            </div>
        );
    }

    processClick(currentUserVoted) {
        if (this.props.voteEnabled === false) {
            return;
        }

        if (!this.props.userInfo.loggedIn) {
            alert('You must be logged in to vote.');
            return;
        }

        if (currentUserVoted) {
            this.props.removeVote();
        } else {
            this.props.addVote(this.props.voteType);
        }
    }
}
