import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { FicRecs } from './components/FicRecs';
import { Users } from './components/Users';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            userInfo: {
                loading: true,
                loggedIn: false,
                user: {}
            }
        };
        this.setUserInfo = this.setUserInfo.bind(this);
        this.reloadUser = this.reloadUser.bind(this);
        this.state.userInfo.setUserInfo = this.setUserInfo;
        this.state.userInfo.reload = this.reloadUser;
    }

    componentDidMount() {
        this.reloadUser();
    }

    render() {
        return (
            <Layout userInfo={this.state.userInfo}>
                <Switch>
                    <Route exact path='/'>
                        <Redirect to="/ficrecs" />
                    </Route>
                    <Route path="/ficrecs">
                        {({ location }) =>
                            <FicRecs userInfo={this.state.userInfo} location={location} />
                        }
                    </Route>
                    <Route path="/admin/users">
                        <Users userInfo={this.state.userInfo} />
                    </Route>
                </Switch>
            </Layout>
        );
    }

    async reloadUser() {
        this.setUserInfo({
            loading: true,
            loggedIn: false,
            user: {}
        });

        const response = await fetch('api/user/me');
        if (response.status === 401) {
            this.setUserInfo({
                loading: false,
                loggedIn: false,
                user: {}
            });
            return;
        }
        const data = await response.json();
        this.setUserInfo({
            loading: false,
            loggedIn: true,
            user: data
        });
    }

    setUserInfo(userInfo) {
        var state = this.state;
        /*state.userInfo = */Object.assign(state.userInfo, userInfo);
        this.setState(state);
    }
}
