import React, { Component } from 'react';
import { VoteSet } from './VoteSet';
import moment from 'moment';

export class FicRecRowEditor extends Component {
    static displayName = FicRecRowEditor.name;

    static columns = {
        name: {},
        primaryWorld: {},
        crossovers: {},
        mainCharacter: {},
        primaryGenre: {},
        secondaryGenre: {},
        url: {},
        notes: {}
    };

    constructor(props) {
        super(props);

        this.state = {
            fic: this.props.ficrec.fic
        }
        Object.keys(FicRecRowEditor.columns).forEach(c => {
            if (!this.state.fic[c]) {
                this.state.fic[c] = '';
            }
        });

        this.onChange = this.onChange.bind(this);
        this.confirmEdit = this.confirmEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    render() {
        const ficrec = this.props.ficrec;
        const fic = this.state.fic;

        return (
            <tr>
                <td><VoteSet ficrec={ficrec} userInfo={this.props.userInfo} voteEnabled={false} /></td>
                {Object.entries(FicRecRowEditor.columns).map(c =>
                    <td key={c[0]}><input type="text" value={fic[c[0]]} onChange={event => this.onChange(c[0], event)} /></td>
                )}
                <td><span title={ficrec.addedUtc}>{moment(ficrec.addedUtc).fromNow()}</span></td>
                <td><div style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ cursor: 'pointer' }} onClick={this.confirmEdit}><span role="img" aria-label="Confirm Button">✔</span></div>
                    <div style={{ cursor: 'pointer' }} onClick={this.cancelEdit}><span role="img" aria-label="Cancel Button">❌</span></div>
                </div></td>
            </tr>
        );
    }

    onChange(field, event) {
        let state = this.state;
        state.fic[field] = event.target.value;
        this.setState(state);
    }

    async confirmEdit() {
        if (this.props.ficrec.id < 0) {
            const response = await fetch('api/ficrecs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fic: this.state.fic
                })
            });
            const data = await response.json();
            this.props.setFicrec(data);
        } else {
            await fetch('api/ficrecs/' + this.props.ficrec.id + '/fic', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.fic)
            });
            this.props.reloadFicrec();
        }
    }

    cancelEdit() {
        this.props.cancelEdit();
    }
}
