import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

export class AdminNavItem extends Component {
    static displayName = AdminNavItem.name;

    componentDidMount() {
    }

    render() {
        if (!(this.props.userInfo.loggedIn && this.props.userInfo.user.isAdmin)) {
            return <React.Fragment />
        }

        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Admin
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem tag={Link} to="/admin/users">
                        Users
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}
