import React, { Component } from 'react';
import { FicRecRow } from './FicRecRow';
import { SortableHeader } from './SortableHeader';
import { Button } from 'reactstrap';

export class FicRecs extends Component {
    static displayName = FicRecs.name;

    static textSortComp = { asc: (a, b) => a.localeCompare(b), desc: (a, b) => b.localeCompare(a) };
    static voteCompFn = (a, b) => {
        const aV = a.reduce((acc, v) => {
            if (v.type === 'Good') {
                acc += 1;
            } else if (v.type === 'Bad') {
                acc -= 1;
            }
            return acc;
        }, 0);
        const bV = b.reduce((acc, v) => {
            if (v.type === 'Good') {
                acc += 1;
            } else if (v.type === 'Bad') {
                acc -= 1;
            }
            return acc;
        }, 0);

        if (aV > bV) {
            return -1;
        } else if (bV > aV) {
            return 1;
        } else {
            return 0;
        }
    };
    static voteSortComp = { asc: (a, b) => FicRecs.voteCompFn(a, b), desc: (a, b) => FicRecs.voteCompFn(b, a) }
    static columns = {
        votes: {
            title: "Votes", compare: FicRecs.voteSortComp, isFRProp: true
        },
        name: { title: "Name", compare: FicRecs.textSortComp },
        primaryWorld: { title: "World", compare: FicRecs.textSortComp },
        crossovers: { title: "Crossover", compare: FicRecs.textSortComp },
        mainCharacter: { title: "Main Character", compare: FicRecs.textSortComp },
        primaryGenre: { title: "Primary Genre", compare: FicRecs.textSortComp },
        secondaryGenre: { title: "Secondary Genre", compare: FicRecs.textSortComp },
        url: { title: "Link", sortEnabled: false },
        notes: { title: "Notes", compare: FicRecs.textSortComp },
        addedUtc: { title: "Date Added", compare: FicRecs.textSortComp, isFRProp: true },
        addedBy: { title: "Recommended By", sortEnabled: false },
        tools: { title: "Tools", sortEnabled: false, recommenderOnly: false }
    };

    constructor(props) {
        super(props);

        this.state = {
            ficrecs: [],
            loading: true,
            currentSort: {}
        };

        this.newFicrecId = -1;

        this.reloadFicrecs = this.reloadFicrecs.bind(this);
        this.newFicrec = this.newFicrec.bind(this);
        this.setFicrec = this.setFicrec.bind(this);
        this.removeFicrec = this.removeFicrec.bind(this);
        this.setSort = this.setSort.bind(this);
    }

    async componentDidMount() {
        // make sure we only scroll after the initial sort
        this.holdScroll = true;

        await this.populateData();

        this.holdScroll = false;

        this.setSort({
            key: 'addedUtc',
            direction: 'desc'
        });
    }

    componentDidUpdate() {
        if (!this.holdScroll && this.shouldScrollToRef) {
            if (this.initialScrollRef && this.initialScrollRef.current) {
                this.initialScrollRef.current.scrollIntoView();
            } else {
                alert('Could not find rec in list.');
            }
            this.shouldScrollToRef = false;
        }
    }

    renderFicRecsTable(ficrecs) {
        if (this.props.location.hash && this.props.location.hash[0] === '#') {
            const hashString = this.props.location.hash.substr(1);
            const params = new URLSearchParams(hashString);
            const newScrollId = params.get('highlight');
            if (this.initialScrollId !== newScrollId) {
                this.initialScrollId = newScrollId;
                this.initialScrollRef = React.createRef();
                this.shouldScrollToRef = true;
            }
        }

        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr className="text-nowrap">
                        {Object.entries(FicRecs.columns).filter(c => !c[1].isRecommender || this.props.userInfo.user.isRecommender || this.props.userInfo.user.isAdmin).map(c =>
                            <th key={c[0]}><SortableHeader setSort={this.setSort} currentSort={this.state.currentSort} sortKey={c[0]} sortEnabled={c[1].sortEnabled !== false}>{c[1].title}</SortableHeader></th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {ficrecs.map(ficrec => 
                        <FicRecRow key={ficrec.id} ficrec={ficrec} highlight={(this.initialScrollId && this.initialScrollId === String(ficrec.id))} ref={(this.initialScrollId && this.initialScrollId === String(ficrec.id)) ? this.initialScrollRef : false} userInfo={this.props.userInfo} setFicrec={this.setFicrec} removeFicrec={this.removeFicrec} />
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderFicRecsTable(this.state.ficrecs);

        return (
            <React.Fragment>
                <h1 id="tabelLabel" >Fic recommendations</h1>
                <p>From the Shiro Gaming Omniverse discord</p>
                <Button style={{ marginBottom: '10px' }} onClick={this.newFicrec}>Add fic</Button>
                {contents}
            </React.Fragment>
        );
    }

    async reloadFicrecs() {
        await this.populateData();
    }

    async populateData() {
        const response = await fetch('api/ficrecs');
        const data = await response.json();
        this.setState({ ficrecs: data, loading: false, currentSort: {} });
    }

    newFicrec() {
        if (!this.props.userInfo.loggedIn) {
            alert('You must be logged in to add a fic.');
            return;
        }

        let state = this.state;
        state.ficrecs.splice(0, 0, { id: this.newFicrecId--, votes: [], fic: {} });
        this.setState(state);
    }

    setFicrec(id, data) {
        let state = this.state;
        let index = state.ficrecs.findIndex(fr => fr.id === id);
        state.ficrecs[index] = data;
        this.setState(state); 
    }

    removeFicrec(id) {
        let state = this.state;
        let index = state.ficrecs.findIndex(fr => fr.id === id);
        state.ficrecs.splice(index, 1);
        this.setState(state);
    }

    doSort(ficrecs, sort) {
        ficrecs.sort((frA, frB) => {
            if (frA.id < 0 && frB.id >= 0) {
                return -1;
            } else if (frA.id >= 0 && frB.id < 0) {
                return 1;
            }

            let a, b;

            if (FicRecs.columns[sort.key].isFRProp) {
                a = frA[sort.key];
                b = frB[sort.key];
            } else {
                a = frA.fic[sort.key];
                b = frB.fic[sort.key];
            }

            return FicRecs.columns[sort.key].compare[sort.direction](a, b);
        });
    }

    setSort(sort) {
        let state = this.state;
        state.currentSort = sort;
        this.doSort(state.ficrecs, sort);
        this.setState(state);
    }
}
