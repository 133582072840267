import React, { Component } from 'react';

export class UserProfile extends Component {
    static displayName = UserProfile.name;

    render() {
        let user = this.props.user;
        let avatarUri = new URL(user.avatarUri);
        avatarUri.searchParams.set('size', 32);

        return (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                {user.discordUsername}#{user.discordDiscriminator}
                <img alt="profile" src={avatarUri} style={{ clipPath: 'circle()', height: '24px', width: '24px', marginLeft: '5px' }} />
            </div>
        );
    }
}
