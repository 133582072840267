import React, { Component } from 'react';
import { UserProfile } from './UserProfile';
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export class UserNavItem extends Component {
    static displayName = UserNavItem.name;

    constructor(props) {
        super(props);

        this.doLogin = this.doLogin.bind(this);
        this.doLogout = this.doLogout.bind(this);
    }

    componentDidMount() {
    }

    getRenderContents() {
        if (this.props.userInfo.loading) {
            return <p><em>Loading...</em></p>;
        }
        if (!this.props.userInfo.loggedIn) {
            return <Button onClick={this.doLogin}>Log in with Discord</Button>;
        }

        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <UserProfile user={this.props.userInfo.user} />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={this.doLogout}>
                        Log out
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    render() {
        let contents = this.getRenderContents();

        return (
            <React.Fragment>
                {contents}
            </React.Fragment>
        );
    }

    doLogin() {
        window.location.replace('/api/user/login');
    }

    async doLogout() {
        await fetch('/api/user/logout', { method: 'POST' });
        this.props.userInfo.reload();
    }
}
