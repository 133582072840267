import React, { Component } from 'react';

export class SortableHeader extends Component {
    static displayName = SortableHeader.name;

    static upArrowInactive = '△';
    static upArrowActive = '▲';
    static downArrowInactive = '▽';
    static downArrowActive = '▼';

    constructor(props) {
        super(props);

        this.setSort = this.setSort.bind(this);
    }

    render() {
        let upArrow = SortableHeader.upArrowInactive;
        let downArrow = SortableHeader.downArrowInactive;

        if (this.props.sortKey === this.props.currentSort.key) {
            if (this.props.currentSort.direction === 'asc') {
                upArrow = SortableHeader.upArrowActive;
            }
            if (this.props.currentSort.direction === 'desc') {
                downArrow = SortableHeader.downArrowActive;
            }
        }

        return (
            <div style={this.props.sortEnabled ? { cursor: 'pointer' } : {}} onClick={this.setSort} >
                {this.props.children} {this.props.sortEnabled ? (upArrow + downArrow) : ('')}
            </div>
        );
    }

    setSort() {
        if (!this.props.sortEnabled) {
            return;
        }

        let direction = 'asc';

        if (this.props.sortKey === this.props.currentSort.key) {
            direction = this.props.currentSort.direction === 'asc' ? 'desc' : 'asc';
        }

        this.props.setSort({
            key: this.props.sortKey,
            direction: direction
        });
    }
}
