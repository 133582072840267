import React, { Component } from 'react';
import { VoteSet } from './VoteSet';
import { FicRecRowEditor } from './FicRecRowEditor';
import moment from 'moment';

export class FicRecRow extends Component {
    static displayName = FicRecRow.name;

    constructor(props) {
        super(props);

        this.state = { inEditMode: false };

        if (this.props.ficrec.id < 0) {
            this.state = { inEditMode: true };
        }

        this.myRef = React.createRef();

        this.setFicrec = this.setFicrec.bind(this);
        this.reloadFicrec = this.reloadFicrec.bind(this);
        this.editRow = this.editRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    render() {
        const ficrec = this.props.ficrec;

        if (this.state.inEditMode) {
            return (
                <FicRecRowEditor ficrec={ficrec} userInfo={this.props.userInfo} setFicrec={this.setFicrec} reloadFicrec={this.reloadFicrec} cancelEdit={this.cancelEdit} />
            );
        }

        let rowColor = '';
        const goodVote = ficrec.votes.filter(v => v.type === 'Good').length;
        const badVote = ficrec.votes.filter(v => v.type === 'Bad').length;
        let voteSum = goodVote - badVote;
        if (voteSum > 5) {
            rowColor = '#00FF0022';
        }

        if (voteSum < 0) {
            rowColor = '#FFAA0022';
        }

        if (voteSum < -5) {
            rowColor = '#FF000022';
        }

        return (
            <tr ref={this.myRef} className={this.props.highlight ? 'table-primary' : ''} style={{ backgroundColor: rowColor }}>
                <td><VoteSet ficrec={ficrec} reloadFicrec={this.reloadFicrec} userInfo={this.props.userInfo} /></td>
                <td><a href={ficrec.fic.url} target="_blank" rel="noopener noreferrer">{ficrec.fic.name}</a></td>
                <td>{ficrec.fic.primaryWorld}</td>
                <td>{ficrec.fic.crossovers}</td>
                <td>{ficrec.fic.mainCharacter}</td>
                <td>{ficrec.fic.primaryGenre}</td>
                <td>{ficrec.fic.secondaryGenre}</td>
                <td><a className="fic-link-emoji" href={ficrec.fic.url} target="_blank" rel="noopener noreferrer"><span role="img" aria-label="Fic Link">📖</span></a></td>
                <td>{ficrec.fic.notes}</td>
                <td><span title={ficrec.addedUtc}>{moment(ficrec.addedUtc).fromNow()}</span></td>
                <td>{ficrec.addedBy?.discordUsername}</td>
                {this.navigationRow()}
            </tr>
        );
    }

    navigationRow() {
        const cellContent = <div className="tool-nav-container">
                                <div style={{ cursor: 'pointer' }} onClick={this.editRow}><span role="img" aria-label="Edit Button">📝</span></div>
                                <div style={{ cursor: 'pointer' }} onClick={this.deleteRow}><span role="img" aria-label="Delete Button">🗑️</span></div>
        </div>;

        if (this.props.userInfo.user.isAdmin || this.props.userInfo.user.isRecommender) {
            return <td>{cellContent}</td>;
        }
        
        const ficReccerId = this.props.ficrec.addedBy?.id;
        if (this.props.userInfo.user.id === ficReccerId) {
            return <td>{cellContent}</td>;
        }

        return <td></td>;
    }

    scrollIntoView() {
        if (this.myRef.current) {
            this.myRef.current.scrollIntoView(true);
        }
    }

    setFicrec(fr) {
        this.setState({
            inEditMode: false
        });
        this.props.setFicrec(this.props.ficrec.id, fr);
    }

    async reloadFicrec() {
        this.setState({
            inEditMode: false
        });
        await this.populateData();
    }

    async populateData() {
        const response = await fetch('api/ficrecs/' + this.props.ficrec.id);
        const data = await response.json();
        this.props.setFicrec(this.props.ficrec.id, data);
    }

    editRow() {
        this.setState({
            inEditMode: true
        });
    }

    cancelEdit() {
        if (this.props.ficrec.id < 0) {
            this.props.removeFicrec(this.props.ficrec.id);
        }

        this.setState({
            inEditMode: false
        });
    }

    async deleteRow() {
        if (!window.confirm('Delete ' + this.props.ficrec.fic.name + '?')) {
            return;
        }
        await fetch('api/ficrecs/' + this.props.ficrec.id, {
            method: 'DELETE',
        });
        this.props.removeFicrec(this.props.ficrec.id);
    }
}
