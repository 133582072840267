import React, { Component } from 'react';
import { ButtonGroup, Button } from 'reactstrap';

export class BinaryToggleButton extends Component {
    static displayName = BinaryToggleButton.name;

    render() {
        return (
            <ButtonGroup>
                <Button color={this.props.value ? "secondary" : "primary"} onClick={() => this.props.onClick(false)} disabled={this.props.disabled} active={!this.props.value}>No</Button>
                <Button color={this.props.value ? "primary" : "secondary"} onClick={() => this.props.onClick(true)} disabled={this.props.disabled} active={this.props.value}>Yes</Button>
            </ButtonGroup>
        );
    }
}
