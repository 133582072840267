import React, { Component } from 'react';
import { VotePart } from './VotePart';

export class VoteSet extends Component {
    static displayName = VoteSet.name;

    static voteTypes = {
        'Good': { symbol: '😃' },
        'Neutral': { symbol: '😐' },
        'Bad': { symbol: '☹️' }
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {Object.entries(VoteSet.voteTypes).map(vt =>
                    <VotePart key={vt[0]} symbol={vt[1].symbol} voteType={vt[0]} addVote={this.addVote.bind(this)} removeVote={this.removeVote.bind(this)} voteSource={this.props.ficrec.votes} userInfo={this.props.userInfo} voteEnabled={this.props.voteEnabled} />
                )}
            </div>
        );
    }

    async addVote(type) {
        await fetch('api/ficrecs/' + this.props.ficrec.id + '/votes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: type
            })
        });
        await this.props.reloadFicrec();
    }

    async removeVote() {
        await fetch('api/ficrecs/' + this.props.ficrec.id + '/votes/me', {
            method: 'DELETE'
        });
        await this.props.reloadFicrec();
    }
}
